.ride-address {
  display: grid;
  border: 1px solid;
  width: fit-content;
  margin: 50px;
}
.map-height {
  height: 500px;
}
.date-label {
  position: absolute;
  top: 70px;
  margin: 40px 0px 0px 30px;
  font-size: 15px;
}
.drop-location-div img {
  height: fit-content;
  margin: 0px 6px 0px -4px;
}
.modal-header hr {
  margin-top: -1px;
  margin-bottom: 0rem;
  border: 0;
  border-top: 10px solid #8fe139;
}
.horizontal-line {
  border-right: 330px solid transparent;
  position: absolute;
  top: 75px;
  border-top: 12px solid #8fe139;
}
.your-ride-with-div {
  margin: 15px 0px 0px 0px;
  width: 35%;
}
.rating-detail-div {
  text-align: center;
  justify-content: center;
  margin-block-start: 20px;
}
.pickup-drop-card {
  display: block !important;
}
.star-ratings {
  position: relative;
  box-sizing: border-box;
  display: inline-block;
}
.modal-header {
  background: #181d33;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem 2rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header label {
  color: #8fe139;
  margin: 0px 0px 0px 95px;
  font-size: 18px;
}
.modal-dialog {
  max-width: 25%;
  margin: 1.75rem auto;
}
.see-receipt-label {
  position: absolute;
  top: 70px;
  margin: 70px 0px 0px 30px;
  width: 40%;
  font-size: 25px;
}

.modal-header .close {
  margin: -1rem -1rem -1rem auto;
  padding: 1rem 0.1rem;
  float: left;
}
.cost-div {
  text-align: center;
}
.cost-div img {
  width: 170px;
}
.amount-div {
  justify-content: space-between;
  display: flex;
}
.close-icon {
  color: #8fe139;
  cursor: pointer;
  margin-block-start: 5px;
}
.total-amount-div i {
  color: #8fe139;
}
.amount-div label {
  display: flex;
}
.amount-div i {
  color: #8fe139;
}
.total-amount-div {
  text-align: center;
  display: inline-flex;
}
.total-amount-div label {
  margin-inline-end: 15px;
  display: flex;
}
.rides-div {
  margin-block-start: 50px;
}
.main_section button:hover {
  color: white;
}
.sub-main-section {
  width: 70%;
  margin: auto;
  background-color: #fff;
  padding-top: 20px;
}
.main_section {
  width: 100%;
  background-size: 100% 100%;
  background-image: url("../../../asset/images/UserDetailBackgroung.png");
}
.main_section i {
  color: #8fe139;
}
.start-location-icon {
  color: black !important;
}
.pickup-div {
  display: flex;
}
.pickup-div i {
  line-height: 1.5 !important;
}
.margin-block-end {
  margin-block-end: 25px;
}
.pickup-div i {
  margin-inline-end: 10px;
}
.drop-location-div {
  display: flex;
}
.drop-location-div i {
  font-size: 21px;
  margin-inline-end: 10px;
}
.vertical-line {
  border-left: 1px solid;
  height: 80px;
  width: 1px;
  margin: -60px 0px 5px 7px;
}
.card-padding {
  padding: 0.75rem 2.6rem !important;
}
.rating-invoice-div {
  display: flex;
  justify-content: space-between;
}
.rating-invoice-div button {
  border: none;
  background: white;
  font-weight: 600;
}
.rating-invoice-div button:hover {
  color: #8fe139;
}
.rating-invoice-div p {
  font-weight: 600;
  margin-bottom: 0rem !important;
}
.fa-rotate-45 {
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
  transition: all 1s ease-out 0s;
  margin-inline-end: 10px;
}
.expanded-card {
  padding: 0.75rem 1.25rem;
  border: 1px solid lightgray !important;
  border-radius: 0 !important;
  background-color: white !important;
}
.currency-icon {
  margin-inline-end: 25px;
}
.ride-date-div {
  width: 35%;
}
.ride-detail-address {
  width: 65%;
}
.form-section-width {
  width: 90% !important;
  margin: 0 auto;
}
.form-container-width {
  width: 100% !important;
  margin: 0 auto;
}
.chevron-right {
  margin-inline-end: 10px;
}
.doller-icon-ride {
  margin-inline-start: 0px;
  margin-inline-end: 5px;
}
.card {
  border: none !important;
  background-color: white !important;
}
.toggle-buttons {
  border: none;
  background: #b7b5b5;
  padding: 10px 30px 10px 30px;
  margin-inline-start: 2px;
  font-weight: 700;
}
.ride-button {
  border: none;
  background: #8fe139;
  padding: 10px 30px 10px 30px;
  margin-inline-start: 2px;
  font-weight: 700;
  cursor: pointer;
}
.tax-button {
  border: none;
  background: #8fe139;
  padding: 10px 30px 10px 30px;
  margin-inline-start: 2px;
  font-weight: 700;
  cursor: pointer;
}
.toggle-buttons-detail-div {
  width: 100%;
  text-align: center;
  margin: 15px 0px 30px 0px;
}
.toggle-buttons:focus {
  outline: none !important;
  outline: 0;
  background: #8fe139;
}
.ride-button:focus {
  outline: none !important;
  outline: 0;
  background: #8fe139;
}
.tax-button:focus {
  outline: none !important;
  outline: 0;
  background: #8fe139;
}
.start-address {
  display: grid;
}
.d-flex {
  display: flex;
}
.mytextwithicon {
  position: relative;
}
.mytextwithicon:before {
  content: "\25C6";
  left: -19px;
  position: absolute;
  top: 0;
  margin: 0px 10px 0px 0px;
}

@media only screen and (min-width: 240px) and (max-width: 479px) {
  .card-padding {
    padding: 1rem 1rem !important;
  }
  .map-height {
    height: 400px;
  }
  .modal-dialog {
    max-width: 100% !important;
    margin: 1.75rem auto;
  }
  .rating-invoice-div {
    display: block !important;
    justify-content: space-between !important;
    padding-block: 2% !important;
    margin-top: 0px !important;
    text-align: center;
  }
  .sub-main-section {
    width: 90%;
    margin: auto;
    background-color: #fff;
    padding-top: 20px;
  }
  .d-flex {
    display: block !important;
    text-align: center;
    justify-content: space-between;
  }
  .rides-div {
    margin-block-start: 50px;
    width: 90% !important;
    font-size: 13px;
    margin: auto;
    margin-block-end: 10%;
  }
  .ride-detail-address {
    width: 100%;
    text-align: left;
  }
  .your-ride-with-div {
    margin: 10% 0px 0px 0px;
    width: 100%;
  }
  .rating-invoice-div {
    display: block !important;
    justify-content: space-between !important;
    padding-block: 2% !important;
    margin-top: -25px;
    text-align: center;
  }
  .ride-date-div {
    width: 100%;
    text-align: left;
  }
  .form-section-width {
    width: 100% !important;
    margin: 0 auto;
  }
  .form-container-width {
    width: 100% !important;
    margin: 0 auto;
    padding-left: 0px;
    padding-right: 0px;
  }
  .ride-button {
    border: none;
    background: #8fe139;
    padding: 10px 10px 10px 10px;
    margin-inline-start: 2px;
    font-weight: 700;
    cursor: pointer;
  }
  .toggle-buttons {
    border: none;
    background: #b7b5b5;
    padding: 10px 10px 10px 10px;
    margin-inline-start: 2px;
    font-weight: 700;
  }
}
@media only screen and (min-width: 480px) and (max-width: 929px) {
  .card-padding {
    padding: 1rem 1rem !important;
  }
  .map-height {
    height: 400px;
  }
  .modal-dialog {
    max-width: 65% !important;
    margin: 1.75rem auto;
  }
  .rating-invoice-div {
    display: block !important;
    justify-content: space-between !important;
    padding: 0 !important;
    margin-top: 0px !important;
    text-align: center;
  }
  .sub-main-section {
    width: 90%;
    margin: auto;
    background-color: #fff;
    padding-top: 20px;
  }
  .d-flex {
    display: block !important;
    text-align: center;
    justify-content: space-between;
  }
  .rides-div {
    margin-block-start: 50px;
    width: 90% !important;
    font-size: 14px;
    margin: auto;
    margin-block-end: 7%;
  }
  .ride-detail-address {
    width: 100%;
    text-align: left;
  }
  .your-ride-with-div {
    margin: 10% 0px 0px 0px;
    width: 100%;
  }
  .rating-invoice-div {
    display: block !important;
    justify-content: space-between !important;
    padding-block: 2% !important;
    margin-top: -25px;
    text-align: center;
  }
  .ride-date-div {
    width: 100%;
    text-align: left;
  }
  .form-section-width {
    width: 100% !important;
    margin: 0 auto;
  }
  .form-container-width {
    width: 100% !important;
    margin: 0 auto;
    padding-left: 0px;
    padding-right: 0px;
    max-width: 720px;
  }
  .ride-button {
    border: none;
    background: #8fe139;
    padding: 10px 10px 10px 10px;
    margin-inline-start: 2px;
    font-weight: 700;
    cursor: pointer;
  }
  .toggle-buttons {
    border: none;
    background: #b7b5b5;
    padding: 10px 10px 10px 10px;
    margin-inline-start: 2px;
    font-weight: 700;
  }
}

@media only screen and (min-width: 929px) and (max-width: 1330px) {
  .modal-dialog {
    max-width: 40% !important;
    margin: 1.75rem auto;
  }
}
