.login-btn {
  text-align: center;
  margin: 6% 0px 0px 0px;
}
.sub_main_form_section {
  width: 70%;
  margin: auto;
  padding-block-end: 1%;
}
.password-section {
  position: relative;
}
.password-strength-bar img {
  height: 10px;
  margin: 0px 0px 15px 0px;
}
.password-tooltip-width {
  width: 37%;
}
.password-tooltip-div {
  justify-content: space-between;
  padding-block-start: 8px;
  display: flex;
}
.password-tip-icon {
  font-size: 25px;
  padding: 0px 0px 7px 0px;
}
.strong-password {
  color: #8fe139 !important;
}
.medium-password {
  color: #f5b342 !important;
}
.low-password {
  color: #ff0324 !important;
}
.password-section i {
  position: absolute;
  padding: 2%;
  top: 10%;
  right: 3%;
  color: #8fe139;
  cursor: pointer;
}
.password-strength-bar img {
  height: 10px;
  margin: 0px 0px 15px 0px;
}
.error-div-reset-pass {
  background: red;
  color: white;
  font-weight: 500;
  border-radius: 10px;
  padding: 10px;
  margin-block-end: 40px;
  display: flex;
  justify-content: space-between;
}
.error-div-reset-pass i {
  color: white;
  font-size: 25px;
  font-weight: 100;
  margin: auto;
}
.error-div-reset-pass i:hover {
  cursor: pointer;
  font-weight: 600;
}
.error-div-reset-pass label {
  margin-bottom: 0rem;
  width: 90%;
  margin-inline-start: 5px;
}
.resend-otp {
  color: #007bff;
  font-weight: bold;
  margin: 17px 0px 0px 0px;
  text-align: right;
  width: 100%;
  cursor: pointer;
}
.resend-otp label:hover {
  color: #8fe139;
  text-decoration: underline;
  cursor: pointer;
}
.heading-confirm-password {
  /* font-family: "ITC Conduit Cyrillic" !important; */
  padding: 30px 0px 15px 0px;
  font-size: 45px;
  font-weight: 500;
}
.forget-password-container input {
  height: 44px !important;
  -moz-box-shadow: inset 0 0 21px #00000017;
  box-shadow: inset 0 0 21px #00000017;
  border-radius: 0px;
}
.forget-password-container select {
  height: 44px !important;
  -moz-box-shadow: inset 0 0 21px #00000017;
  box-shadow: inset 0 0 21px #00000017;
  border-radius: 0px;
}
.confirm-password-container input {
  height: 44px !important;
  -moz-box-shadow: inset 0 0 21px #00000017;
  box-shadow: inset 0 0 21px #00000017;
  border-radius: 0px;
}
.confirm-password-container select {
  height: 44px !important;
  -moz-box-shadow: inset 0 0 21px #00000017;
  box-shadow: inset 0 0 21px #00000017;
  border-radius: 0px;
}

.Log-in {
  text-align: center;
  margin-bottom: 26px;
  margin-top: 26px;
  border-bottom: 3px solid black;
}

.link {
  cursor: pointer;
}
.text-center {
  text-align: center;
  padding: 6%;
}

.forget-password-container {
  width: 670px;
  margin: 0px auto;
  border: 1px solid lightgrey;
  margin-top: 7%;
  padding: 0px 70px;
  box-shadow: 0px 0px 10px 0px #e3e2e2;
  border-radius: 8px;
  display: table;
}
.confirm-password-container {
  width: 670px;
  margin: 0px auto;
  border: 1px solid lightgrey;
  margin-top: 5%;
  padding: 0px 70px;
  box-shadow: 0px 0px 10px 0px #e3e2e2;
  border-radius: 8px;
  display: table;
}
.heading_vooy {
  font-size: 40px !important;
  font-weight: 600 !important;
  text-align: center;
  padding-bottom: 30px;
}
.forget_button_div button {
  background: #8fe139;
  color: white;
  border: none;
  border-radius: 30px;
  width: 240px;
  height: 40px;
  margin-top: 10px;
  transition: all 0.5s ease-out 0s;
}
.forget_button_div button:hover {
  color: black;
  background: #8fe139;
  transition: all 0.5s ease-out 0s;
}
.forget_button_div button:focus {
  outline: none !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(254, 94, 0, 0.25);
  background: #8fe139;
  transition: all 0.5s ease-out 0s;
  color: #000000;
}
.forget-button-div-email button {
  background: #b7b5b5;
  color: white;
  border: none;
  border-radius: 30px;
  width: 240px;
  height: 40px;
  margin-top: 10px;
  transition: all 0.5s ease-out 0s;
}
.forget-button-div-email button:hover {
  color: black;
  background: #b7b5b5;
  transition: all 0.5s ease-out 0s;
}
.forget-button-div-email button:focus {
  outline: none !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(254, 94, 0, 0.25);
  background: #b7b5b5;
  transition: all 0.5s ease-out 0s;
  color: #000000;
}
.forget-button-div-email-enabled button {
  background: #8fe139;
  color: white;
  border: none;
  border-radius: 30px;
  width: 240px;
  height: 40px;
  margin-top: 10px;
  transition: all 0.5s ease-out 0s;
}
.forget-button-div-email-enabled button:hover {
  color: black;
  background: #8fe139;
  transition: all 0.5s ease-out 0s;
}
.forget-button-div-email-enabled button:focus {
  outline: none !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(254, 94, 0, 0.25);
  background: #8fe139;
  transition: all 0.5s ease-out 0s;
  color: #000000;
}
.show_password_position {
  float: right;
  margin: -25px 15px 0px 0px;
}
.password_section {
  position: relative;
}
.password_section input {
  position: relative;
}
.password_section i {
  position: absolute;
  right: 17px;
  top: 0px;
  padding: 10px;
}

.password_section i:hover {
  cursor: pointer;
}
.confirm_pass_title {
  text-align: center;
  padding: 30px;
}
.change_pass_button {
  display: flex;
  margin-block: 8%;
}
.forget_button_width {
  width: 82%;
}
.resend_otp_div {
  margin-top: 15px;
  color: #007bff;
  cursor: pointer;
}
@media only screen and (min-width: 501px) and (max-width: 940px) {
  .heading-confirm-password {
    /* font-family: "ITC Conduit Cyrillic" !important; */
    padding: 30px 0px 15px 0px;
    font-size: 40px;
    font-weight: 500;
  }
  .password-section i {
    position: absolute;
    padding: 2%;
    top: 17%;
    right: 3%;
    color: #8fe139;
    cursor: pointer;
  }
  .forget-password-container {
    width: 400px;
    margin: 0px auto;
    border: 1px solid lightgrey;
    margin-top: 7%;
    padding: 0px 70px;
    box-shadow: 0px 0px 10px 0px #e3e2e2;
    border-radius: 8px;
    display: table;
  }
  .confirm-password-container {
    width: 460px;
    margin: 0px auto;
    border: 1px solid lightgrey;
    margin-top: 5%;
    padding: 0px 40px;
    box-shadow: 0px 0px 10px 0px #e3e2e2;
    border-radius: 8px;
    display: table;
  }
  .heading_vooy {
    font-size: 35px !important;
    font-weight: 600;
    text-align: center;
    padding-bottom: 30px;
  }
  .forget-button-div-email button {
    background: #8fe139;
    color: white;
    border: none;
    border-radius: 30px;
    width: 240px;
    height: 40px;
    margin-top: 10px;
    transition: all 0.5s ease-out 0s;
  }
  .forget-button-div-email button:hover {
    color: black;
    background: #8fe139;
    transition: all 0.5s ease-out 0s;
  }
  .forget-button-div-email button:focus {
    outline: none !important;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(254, 94, 0, 0.25);
    background: #8fe139;
    transition: all 0.5s ease-out 0s;
    color: #000000;
  }
  .label_size {
    font-size: 15px;
  }
  .text-center {
    text-align: center;
    padding: 30px 0px 30px 0px;
    width: max-content;
  }
  .confirm_pass_title {
    text-align: center;
    padding: 30px 0px 30px 0px;
    font-size: 25px;
  }
  .forget_button_div button {
    background: #8fe139;
    color: white;
    border: none;
    width: 170px;
    font-size: 14px;
    border-radius: 30px;
    height: 40px;
    margin-top: 10px;
    transition: all 0.5s ease-out 0s;
  }
  .forget_button_div button:hover {
    color: black;
    background: #8fe139;
    transition: all 0.5s ease-out 0s;
  }
  .forget_button_div button:focus {
    outline: none !important;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(254, 94, 0, 0.25);
    background: #8fe139;
    transition: all 0.5s ease-out 0s;
    color: #000000;
  }
  .resend_otp_div {
    margin-top: 15px;
    color: #007bff;
    min-width: fit-content;
    cursor: pointer;
  }
  .sub-main-section-otp {
    width: 70% !important;
  }
  .sub_main_form_section {
    width: 70%;
    margin: auto;
    padding-block-end: 1%;
  }
}

@media only screen and (min-width: 240px) and (max-width: 500px) {
  .main_section label {
    font-size: 14px;
    /* font-weight: 500; */
  }
  .password-section i {
    position: absolute;
    padding: 2%;
    top: 20%;
    right: 3%;
    color: #8fe139;
    cursor: pointer;
  }
  .sub_main_form_section {
    width: 80%;
    margin: auto;
  }
  .heading-confirm-password {
    /* font-family: "ITC Conduit Cyrillic" !important; */
    padding: 30px 0px 15px 0px;
    font-size: 35px;
    font-weight: 500;
  }
  .confirm-password-container {
    width: 270px;
    margin: 0px auto;
    padding: 0px 0px;
    display: table;
    border-radius: 0px;
    box-shadow: 0px 0px 0px 0px #e3e2e2;
    border: 0px solid lightgrey;
  }
  .resend-otp {
    color: #007bff;
    font-weight: bold;
    margin: 20px 0px 0px 0px;
    text-align: right;
    width: 100%;
    font-size: 14px;
  }
  .forget-password-container {
    width: 400px;
    margin: 0px auto;
    border: 1px solid lightgrey;
    margin-top: 7%;
    padding: 0px 70px;
    box-shadow: 0px 0px 10px 0px #e3e2e2;
    border-radius: 8px;
    display: table;
  }
  .heading_vooy {
    padding-top: 70px;
    font-size: 30px !important;
    font-weight: 600;
    text-align: center;
    padding-bottom: 30px;
  }
  .label_size {
    font-size: 15px;
  }
  .text-center {
    padding: 30px 0px 30px 0px;
    font-size: 14px;
    width: 100%;
  }
  .confirm_pass_title {
    text-align: center;
    padding: 30px 0px 30px 0px;
    font-size: 25px;
  }
  .forget_button_div button {
    background: #8fe139;
    color: white;
    border: none;
    border-radius: 30px;
    height: 40px;
    width: max-content;
    font-size: 11px;
    margin-top: 10px;
    padding: 0px 10% 0px 10%;
    transition: all 0.5s ease-out 0s;
  }
  .forget_button_div button:hover {
    color: black;
    background: #8fe139;
    transition: all 0.5s ease-out 0s;
  }
  .forget_button_div button:focus {
    outline: none !important;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(254, 94, 0, 0.25);
    background: #8fe139;
    transition: all 0.5s ease-out 0s;
    color: #000000;
  }
  .forget-button-div-email button {
    background: #b7b5b5;
    color: white;
    border: none;
    border-radius: 30px;
    width: 100%;
    height: 40px;
    margin-top: 10px;
    transition: all 0.5s ease-out 0s;
  }
  .forget-button-div-email button:hover {
    color: black;
    background: #b7b5b5;
    transition: all 0.5s ease-out 0s;
  }
  .forget-button-div-email button:focus {
    outline: none !important;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(254, 94, 0, 0.25);
    background: #b7b5b5;
    transition: all 0.5s ease-out 0s;
    color: #000000;
  }
  .forget-button-div-email-enabled button {
    background: #8fe139;
    color: white;
    border: none;
    border-radius: 30px;
    width: 240px;
    height: 40px;
    margin-top: 10px;
    transition: all 0.5s ease-out 0s;
  }
  .forget-button-div-email-enabled button:hover {
    color: black;
    background: #8fe139;
    transition: all 0.5s ease-out 0s;
  }
  .forget-button-div-email-enabled button:focus {
    outline: none !important;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(254, 94, 0, 0.25);
    background: #8fe139;
    transition: all 0.5s ease-out 0s;
    color: #000000;
  }
  .resend_otp_div {
    margin-top: 15px;
    color: #007bff;
    min-width: fit-content;
    cursor: pointer;
  }
}
