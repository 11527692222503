.footer_section_logo_part {
	text-align: center;
}

.modal_ios {
	color: black;
}

.modal_label {
	font-size: 20px;
	font-weight: 600;
}

.footer_companies_logo {
	padding: 0px 50px 10px 50px;
}

.footer_company_logo_vooy {
	height: 100px;
	padding: 10px;
	margin-block-end: 10px;
}

.footer_company_logo_dhl {
	height: 100px;
	width: 200px;
	margin-left: 40px;
}

.companie_logo_back_color {
	background-color: #9d9d9d;
}

.footer_section_social_logo_part {
	background-color: #000000;
	width: 100%;
	height: auto;
	justify-content: center;
}

.app_store_logo_div {
	text-align: center;
	padding-top: 5px;
}
.app_store_logo_div a img {
	width: 10%;
}
.apple_app_store_img {
	width: 166px !important;
}
@media only screen and (min-width: 1024px) and (max-width: 1366px) {
	.app_store_logo_div {
		text-align: center;
	}
	.app_store_logo_div a img {
		width: 12%;
	}
	.apple_app_store_img {
		width: 145px !important;
	}
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
	.app_store_logo_div {
		text-align: center;
	}
	.app_store_logo_div a img {
    width: 21%;
	}
	.apple_app_store_img {
		width: 166px !important;
	}
}
@media only screen and (min-width: 540px) and (max-width: 767px) {
	.app_store_logo_div {
		text-align: center;
	}
	.app_store_logo_div a img {
    width: 27%;
	}
	.apple_app_store_img {
    width: 24%!important;
	}
}
@media only screen and (min-width: 480px) and (max-width: 539px) {
	.app_store_logo_div {
		text-align: center;
	}
	.app_store_logo_div a img {
		width: 26%;
	}
	.apple_app_store_img {
    width: 23% !important;
	}
}
@media only screen and (min-width: 414px) and (max-width: 479px) {
	.app_store_logo_div {
		text-align: center;
		display: grid;
	}
	.app_store_logo_div a img {
		width: 40%;
	}
	.apple_app_store_img {
		width: 35% !important;
	}
	.footer_company_logo_vooy {
		height: 65px;
		padding: 10px;
		margin-block-end: 0px;
	}
}
@media only screen and (min-width: 360px) and (max-width: 413px) {
	.app_store_logo_div {
		text-align: center;
		display: grid;
	}
	.app_store_logo_div a img {
		width: 40%;
	}
	.apple_app_store_img {
		width: 35% !important;
	}
	.footer_company_logo_vooy {
		height: 65px;
		padding: 10px;
		margin-block-end: 0px;
	}
}

@media only screen and (min-width: 320px) and (max-width: 359px) {
	.app_store_logo_div {
		text-align: center;
		display: grid;
	}
	.app_store_logo_div a img {
		width: 50%;
	}
	.apple_app_store_img {
		width: 44% !important;
	}
	.footer_company_logo_vooy {
		height: 65px;
		padding: 10px;
		margin-block-end: 0px;
	}
}
@media only screen and (min-width: 220px) and (max-width: 319px) {
	.app_store_logo_div {
		text-align: center;
		display: grid;
	}
	.app_store_logo_div a img {
		width: 50%;
	}
	.apple_app_store_img {
		width: 44% !important;
	}
	.footer_company_logo_vooy {
		height: 65px;
		padding: 10px;
		margin-block-end: 10px;
	}
}
.logo_section {
	justify-content: center;
	font-size: 25px;
}

.icon_color {
	color: white;
	margin: 20px 20px 0px 20px;
}
.link_section {
	width: 100%;
	justify-content: center;
	margin: 20px 0px 20px 0px;
	color: white;
	font-size: 15px;
	font-family: arial;
	font-weight: 500;
}
.link_section a {
	color: white;
}

.link_left_items {
	margin-left: 13px;
}

.last_para {
	color: white;
	text-align: center;
	padding-bottom: 10px;
}

.vl {
	border-left: 1px solid white;
	margin-left: 10px;
}

.margin_footer {
	margin-top: 20px;
}
.d-block {
	display: flex !important;
}
