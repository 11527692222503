.sub_header_section {
  width: 100%;
  height: 400px;
  background-color: #181d33;
  background-image: url("../../../asset/images/Sub_Header_image.png");
  background-size: 100% 100%, cover;
  text-align: center;
}

.vooyLogo_home_Header_part {
  width: 400px;
  height: 180px;
  margin-top: 100px;
  /* margin-right: 100px; */
}

.vooyLogo_Header_part {
  height: 100px;
  margin-top: 50px;
}

.subHeader_Heading {
  font-size: 45px;
  color: white;
  font-weight: 450;
  margin-top: 40px;
}

.subHeader_home_Heading {
  font-size: 40px;
  color: white;
  font-weight: bold;
  margin-top: 40px;
  /* margin-right: 120px; */
}

.heading_top {
  margin-top: 10px;
}

.home_sub_section {
  width: 400px;
  margin-right: 100px;
}

.successful_note_para {
  text-align: center;
  color: white;
  font-size: 25px;
  font-weight: 450;
  margin-top: 50px;
}

.main_heading_top {
  margin-top: 90px;
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .sub_main_section {
    width: 90%;
  }
  .sub_main_form_section {
    width: 90%;
  }
  .vooyLogo_Header_part {
    height: 100px;
  }
  .subHeader_Heading {
    font-size: 35px;
  }
  .sub_header_section {
    height: 310px;
  }
  .apps_name_div {
    min-width: 50% !important;
  }
  .vooyLogo_home_Header_part {
    margin-top: 60px;
  }
  .vooyLogo_home_Header_part {
    width: 300px;
    height: auto;
  }
  .successful_note_para {
    font-size: 20px;
    padding: 0px 32px;
  }
}

@media only screen and (min-width: 240px) and (max-width: 600px) {
}

@media only screen and (min-width: 240px) and (max-width: 479px) {
  .sub_main_section {
    width: 90%;
  }
  .sub_main_form_section {
    width: 90%;
  }
  .vooyLogo_Header_part {
    height: 60px;
    margin-top: 30px;
  }
  .subHeader_Heading {
    font-size: 35px;
  }
  .sub_header_section {
    height: 200px;
  }
  .apps_name_div {
    min-width: 50% !important;
  }
  .vooyLogo_home_Header_part {
    margin-top: 60px;
  }
  .vooyLogo_home_Header_part {
    width: 300px;
    height: auto;
  }
  .successful_note_para {
    font-size: 20px;
    padding: 0px 32px;
  }
}
