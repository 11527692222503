/* @font-face {
  font-family: "ITC Conduit Cyrillic";
  src: url(https://test-api.vooyapp.com/default_attachments/font/ITC_Conduit_Bold_Cyrillic.ttf);
} */
.main_div {
  background-color: #fff;
}
.remove-dropdown-icon select {
  appearance: none;
  padding-right: 0.75rem !important;
}
.login-ride-logo-div img {
  width: 130px;
}
.heading_vooy {
  font-size: 60px !important;
  font-weight: 500 !important;
  padding-bottom: 20px !important;
}
.sub-main-form-section {
  width: 70% !important;
  margin: auto !important;
  padding-block-end: 1% !important;
}
.sub-main-section-otp {
  width: 50% !important;
  margin: auto !important;
  background-color: white !important;
  padding-top: 20px !important;
}
.w_100 {
  width: 100%;
}
.login-ride-logo-div {
  text-align: center;
  margin: 30px;
}
.flex-div {
  display: flex;
}
.error-div-login {
  background: red;
  color: white;
  font-weight: 500;
  border-radius: 10px;
  padding: 10px;
  margin-block-start: 40px;
  display: flex;
  justify-content: space-between;
}
.error-div-login i {
  color: white;
  font-size: 25px;
  font-weight: 100;
  margin: auto;
}
.error-div-login i:hover {
  cursor: pointer;
  font-weight: 600;
}
.error-div-login label {
  margin-bottom: 0rem;
  width: 90%;
  margin-inline-start: 5px;
}
.change-login-option label:hover {
  color: #8fe139;
  text-decoration: underline;
  cursor: pointer;
}
.login-div {
  padding-block-start: 10px;
}
.signup-language-div {
  padding: 0px 0px 40px 0px;
  justify-content: space-between;
  width: 75%;
  margin: 0 auto;
}
/* .fa-globe-americas {
  -webkit-animation: spin 3s infinite linear;
} */

/* .fa-globe-americas:before {
  -moz-animation: spin 60s infinite linear;
  -o-animation: spin 60s infinite linear;
} */
.country-dropdown select {
  width: auto;
  float: left;
  padding-right: 0px;
}
.language-dropdown {
  display: flex;
  justify-content: center;
  padding: 5% 0px 10% 0px;
}
.language-dropdown i {
  padding: 10px 10px 5px 0px;
  font-size: 25px;
  color: #8fe139;
}
.loginmaindiv_container {
  width: 670px;
  margin: 0px auto;
  border: 1px solid lightgrey;
  margin-top: 5%;
  margin-bottom: 5%;
  padding: 0px 70px;
  box-shadow: 0px 0px 50px 0px #e3e2e2;
  border-radius: 8px;
  display: table;
}
.main_section label {
  font-size: 16px;
  /* font-weight: 500; */
}
.main_section a {
  font-size: 16px;
  font-weight: 600;
}
.main_section a:hover {
  color: #8fe139;
  cursor: pointer;
}
.main_section input {
  height: 44px !important;
  -moz-box-shadow: inset 0 0 21px #00000017;
  box-shadow: inset 0 0 21px #00000017;
  border-radius: 0px;
}
.main_section select {
  height: 44px !important;
  -moz-box-shadow: inset 0 0 21px #00000017;
  box-shadow: inset 0 0 21px #00000017;
  border-radius: 0px;
}
.top_margin {
  margin-top: 25px;
}
.top_margin a {
  color: #1a73e8;
  font-weight: bold;
  padding-left: 5px;
}
.heading_vooy {
  padding: 15px 0px 15px 0px;
  font-size: 50px;
  font-weight: 500;
  text-align: center;
}
.change-login-option {
  color: #007bff !important;
  font-weight: 600 !important;
  margin: 6% 0px 0px 0px;
  text-align: center;
}
.login-button-div {
  display: flex;
  width: 100%;
  text-align: center;
}
.login-button-enabled {
  display: flex;
  width: 100%;
  text-align: center;
}
.justify-content-between {
  justify-content: space-between;
}
.login-button-enabled button {
  background: #8fe139;
  color: white;
  border: none;
  font-weight: 600;
  border-radius: 30px;
  width: 100%;
  height: 40px;
  transition: all 0.5s ease-out 0s;
}
.login-button-enabled button:hover {
  color: black;
  background: #8fe139;
  transition: all 0.5s ease-out 0s;
}
.login-button-enabled button:focus {
  outline: none !important;
  outline: 0;
  background: #8fe139;
  box-shadow: 0 0 0 0.2rem rgba(254, 94, 0, 0.25);
  transition: all 0.5s ease-out 0s;
  color: white;
}
.login-button-div button {
  background: #b7b5b5 !important;
  border: none;
  font-weight: 600;
  border-radius: 30px;
  width: 100%;
  height: 40px;
  transition: all 0.5s ease-out 0s;
}
.login-button-div button:hover {
  color: black;
  background: #8fe139;
  transition: all 0.5s ease-out 0s;
}
.login-button-div button:focus {
  outline: none !important;
  outline: 0;
  background: #8fe139;
  box-shadow: 0 0 0 0.2rem rgba(254, 94, 0, 0.25);
  transition: all 0.5s ease-out 0s;
  color: white;
}
.password_div {
  position: relative;
}
.password_div input {
  position: relative;
}
.password_div i {
  position: absolute;
  padding: 12px;
  top: 35px;
  right: 15px;
  color: #8fe139;
}
.password_div i:hover {
  cursor: pointer;
}

.language_dropdpwn {
  margin-left: 25%;
  margin-top: 7%;
}
.signup-link-span {
  padding-block-start: 10px;
}
.login_link_div {
  padding: 6% 0px 2% 0px;
  justify-content: center;
  text-align: center;
}
.login_link_div label {
  color: #007bff;
}
.login_link_div label:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #8fe139;
}

@media only screen and (min-width: 774px) and (max-width: 1160px) {
  .main_div {
    padding: 0px 50px;
  }
  .Header_Section {
    width: 100%;
    background-color: #181d33;
    display: grid;
    justify-content: unset !important;
  }
  .loginmaindiv_container {
    width: 670px;
    margin: 0px auto;
    border: 1px solid lightgrey;
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 0px 70px;
    box-shadow: 0px 0px 10px 0px #e3e2e2;
    border-radius: 8px;
    display: table;
  }
}

@media only screen and (min-width: 480px) and (max-width: 773px) {
  .main_div {
    padding: 0px 10px;
  }
  .signup-language-div {
    padding: 0px 0px 40px 0px;
    justify-content: space-between;
    width: 95%;
    margin: 0 auto;
  }
  .loginmaindiv_container label {
    font-size: 13px;
    font-weight: 500;
  }
  .loginmaindiv_container {
    width: 460px;
    margin: 0px auto;
    border: 1px solid lightgrey;
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 0px 50px;
    box-shadow: 0px 0px 10px 0px #e3e2e2;
    border-radius: 8px;
  }
  .login-button-div button {
    background: #8fe139;
    color: white;
    border: none;
    border-radius: 30px;
    height: 40px;
    transition: all 0.5s ease-out 0s;
  }
  .top_margin a {
    color: #1a73e8;
    font-weight: bold;
    padding-left: 5px;
    font-size: 13px;
  }
  .justify-content-center {
    justify-content: center !important;
    font-size: 15px;
  }
  .login_link_div {
    padding: 30px 0px;
  }
  .change-login-option {
    color: #007bff !important;
    font-weight: 600 !important;
    margin: 5% 0px 4% 0px;
    text-align: center;
  }
  .forgot_password_div {
    margin: 0px 0px 0px 0px !important;
    min-width: -moz-fit-content;
    min-width: fit-content;
    color: #007bff;
    font-weight: bold;
    text-align: center;
  }
  .top_margin {
    margin-top: 0px;
  }
  .sub-main-form-section {
    width: 80% !important;
    margin: auto;
    padding-block-end: 1%;
  }
  .country-dropdown select {
    width: auto;
  }
  .language-dropdown select {
    width: auto;
  }
  .heading_vooy {
    padding-top: 0px !important;
    font-size: 45px !important;
    font-weight: 600;
    text-align: center;
    padding-bottom: 15px !important;
  }
  .main_div {
    padding: 0px 0px;
  }
  .login-div {
    padding-block-start: 0px !important;
    font-size: 12px;
    text-align: left;
  }
  .signup-language-div {
    padding: 0px 20px 40px 20px;
    justify-content: space-between !important;
    width: 100%;
    margin: 0 auto;
  }
  .signup-link-span {
    padding-block-start: 10px;
    font-size: 12px;
  }
  .sub-main-section-otp {
    width: 70% !important;
  }
}

@media only screen and (min-width: 240px) and (max-width: 479px) {
  .change-login-option {
    color: #007bff !important;
    font-weight: 600 !important;
    margin: 5% 0px 5% 0px;
    text-align: center;
  }
  .password_div i {
    position: absolute;
    padding: 12px;
    top: 32px;
    right: 15px;
    color: #8fe139;
  }
  .sub-main-section-otp {
    width: 90% !important;
  }
  .sub-main-form-section {
    width: 90% !important;
    margin: auto;
    padding-block-end: 1%;
  }
  .country-dropdown select {
    font-size: 12px;
  }
  .language-dropdown select {
    width: auto;
  }
  .heading_vooy {
    padding-top: 0px !important;
    font-size: 45px !important;
    font-weight: 600;
    text-align: center;
    padding-bottom: 15px !important;
  }
  .main_div {
    padding: 0px 0px;
  }
  .login-div {
    padding-block-start: 0px !important;
    font-size: 12px;
    text-align: left;
  }
  .signup-language-div {
    padding: 0px 20px 40px 20px;
    justify-content: space-between !important;
    width: 100%;
    margin: 0 auto;
  }
  .signup-link-span {
    padding-block-start: 10px;
    font-size: 12px;
  }
  .login_link_div a {
    font-size: 14px !important ;
    font-weight: 600 !important;
  }
  .login-button-div {
    display: flex;
    width: 100%;
    text-align: center;
  }
  .d-flex {
    display: inline-flex !important;
    text-align: center;
    justify-content: space-between;
  }
  .login_link_div {
    margin-block-start: 30px;
    width: 100%;
    justify-content: space-between;
    padding: 0px !important;
  }
  .forgot_password_div {
    margin: 6% 0px 0px 0px !important;
    min-width: -moz-fit-content;
    min-width: fit-content;
    text-align: center;
    color: #007bff;
    font-weight: bold;
    text-align: center;
  }
  .justify-content-center {
    justify-content: center !important;
    font-size: 15px;
    width: 100%;
  }
  .top_margin a {
    color: #007bff;
    font-weight: bold;
    font-size: 11px;
    text-align: center !important;
  }
  .loginmaindiv_container {
    margin: 0px auto;
    border: 0px solid lightgrey;
    margin: 0% 0% 0% 0%;
    padding: 0px 25px;
    box-shadow: 0px 0px 0px 0px #e3e2e2;
    border-radius: 0px;
    width: auto;
    display: block !important;
  }
  .loginmaindiv_container label {
    font-size: 12px;
    font-weight: 500;
  }
}
.forgot_password_div {
  margin: 3% 0px 0px 0px;
  min-width: fit-content;
  color: #007bff;
  font-weight: bold;
  text-align: center;
}
.forgot_password_div a:hover {
  color: #8fe139;
}
.forgot_password_div label:hover {
  color: #8fe139;
  text-decoration: underline;
  cursor: pointer;
}
