.ride-address {
  display: grid;
  border: 1px solid;
  width: fit-content;
  margin: 50px;
}
.filter-icon {
  margin-inline-end: 1%;
}
.rating-download-div {
  padding: 0 !important;
}
.widget-svg {
  width: 22px !important;
  height: 24px !important;
}
.rating-div {
  display: flex;
}
.star-ratings {
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  margin: -1px 0px 0px 10px;
}
.select-div {
  margin: 0px 0px 5px 18px;
}
.select-div select {
  height: 44px !important;
  -moz-box-shadow: inset 0 0 21px #00000017;
  border-radius: 0px;
  border: 0px solid grey;
  outline: none !important;
  box-shadow: inset 0 0 0px #00000017;
}
.select-div select:focus {
  outline: none;
}
.start-location-icon {
  color: black !important;
}
.rides-div {
  margin-block-start: 50px;
}
.main_section button:hover {
  color: white;
}
.sub-main-section {
  width: 70%;
  margin: auto;
  background-color: #fff;
  padding-top: 20px;
}
.main_section {
  width: 100%;
  background-size: 100% 100%;
  background-image: url("../../../asset/images/UserDetailBackgroung.png");
}
.arrow-icon {
  color: #8fe139;
}
.pickup-div {
  display: flex;
}
.pickup-div i {
  line-height: 1.5 !important;
}
.margin-block-end {
  margin-block-end: 25px;
}
.pickup-div i {
  margin-inline-end: 10px;
}
.drop-location-div {
  display: flex;
}
.drop-location-div i {
  font-size: 21px;
  margin-inline-end: 10px;
}
.vertical-line {
  border-left: 1px solid;
  height: 80px;
  width: 1px;
  margin: -60px 0px 5px 7px;
}
.card-padding {
  padding: 0.75rem 2.6rem !important;
}
.pickup-drop-card {
  display: flex !important;
}
.rating-invoice-div {
  display: flex;
  justify-content: space-between;
}
.rating-invoice-div button {
  border: none;
  background: white;
  font-weight: 600;
}
.rating-invoice-div button:hover {
  color: #8fe139;
}
.rating-invoice-div p {
  font-weight: 600;
  margin-bottom: 0rem !important;
}
.fa-rotate-45 {
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
  transition: all 1s ease-out 0s;
  margin-inline-end: 10px;
}
.expanded-card {
  padding: 0.75rem 1.25rem;
  border: 1px solid lightgray !important;
  border-radius: 0 !important;
  background-color: white !important;
}
.currency-icon {
  margin-inline-end: 25px;
}
.ride-date-div {
  width: 35%;
}
.ride-address-div {
  width: 65%;
}
.form-section-width {
  width: 90% !important;
  margin: 0 auto;
}
.form-container-width {
  width: 100% !important;
  margin: 0 auto;
}
.chevron-right {
  margin-inline-end: 10px;
}
.doller-icon {
  margin-inline-start: 20px !important;
  margin-inline-end: 5px;
}
.card {
  border: none !important;
  background-color: white !important;
}
.toggle-buttons {
  border: none;
  background: #b7b5b5;
  padding: 10px 30px 10px 30px;
  margin-inline-start: 2px;
  font-weight: 700;
}
.ride-button {
  border: none;
  background: #8fe139;
  padding: 10px 30px 10px 30px;
  margin-inline-start: 2px;
  font-weight: 700;
  cursor: pointer;
}
.tax-button {
  border: none;
  background: #8fe139;
  padding: 10px 30px 10px 30px;
  margin-inline-start: 2px;
  font-weight: 700;
  cursor: pointer;
}
.toggle-buttons-div {
  width: 100%;
  text-align: center;
  margin: 15px 0px 10px 0px;
}
.toggle-buttons:focus {
  outline: none !important;
  outline: 0;
  background: #8fe139;
}
.ride-button:focus {
  outline: none !important;
  outline: 0;
  background: #8fe139;
}
.tax-button:focus {
  outline: none !important;
  outline: 0;
  background: #8fe139;
}
.start-address {
  display: grid;
}
.d-flex {
  display: flex;
}
.mytextwithicon {
  position: relative;
}
.mytextwithicon:before {
  content: "\25C6";
  left: -19px;
  position: absolute;
  top: 0;
  margin: 0px 10px 0px 0px;
}
@media only screen and (min-width: 240px) and (max-width: 479px) {
  .card-padding {
    padding: 1rem 1rem !important;
  }
  .select-div select {
    margin-inline-start: 10px;
  }
  .vertical-line {
    border-left: 1px solid;
    height: 80px;
    width: 1px;
    margin: -86px 0px 0px 7px !important;
  }
  .rating-div {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .sub-main-section {
    width: 90%;
    margin: auto;
    background-color: #fff;
    padding-top: 20px;
  }
  .d-flex {
    display: block !important;
    text-align: center;
    justify-content: space-between;
  }
  .rides-div {
    margin-block-start: 50px;
    width: 90% !important;
    font-size: 13px;
    margin: auto;
  }
  .ride-address-div {
    width: 84% !important;
    text-align: left !important;
    margin: auto !important;
  }
  .ride-date-div {
    width: 100%;
    text-align: left;
  }
  .form-section-width {
    width: 100% !important;
    margin: 0 auto;
  }
  .form-container-width {
    width: 100% !important;
    margin: 0 auto;
    padding-left: 0px;
    padding-right: 0px;
  }
  .ride-button {
    border: none;
    background: #8fe139;
    padding: 10px 10px 10px 10px;
    margin-inline-start: 2px;
    font-weight: 700;
    cursor: pointer;
  }
  .toggle-buttons {
    border: none;
    background: #b7b5b5;
    padding: 10px 10px 10px 10px;
    margin-inline-start: 2px;
    font-weight: 700;
  }
  .rating-invoice-div {
    display: block !important;
    justify-content: space-between !important;
  }
  .rating-invoice-div button {
    border: none;
    background: white;
    font-weight: 600;
    padding: 0 !important;
    margin-block: 1%;
  }
  .arrow-icon {
    color: #8fe139;
    margin-inline-start: 10px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 929px) {
  .card-padding {
    padding: 1rem 1rem !important;
  }
  .vertical-line {
    border-left: 1px solid;
    height: 80px;
    width: 1px;
    margin: -65px 0px 0px 7px !important;
  }
  .rating-div {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .sub-main-section {
    width: 90%;
    margin: auto;
    background-color: #fff;
    padding-top: 20px;
  }
  .d-flex {
    display: block !important;
    text-align: center;
    justify-content: space-between;
  }
  .rides-div {
    margin-block-start: 50px;
    width: 90% !important;
    font-size: 13px;
    margin: auto;
  }
  .ride-address-div {
    width: 92% !important;
    text-align: left !important;
    margin: auto !important;
  }
  .ride-date-div {
    width: 100%;
    text-align: left;
  }
  .form-section-width {
    width: 100% !important;
    margin: 0 auto;
  }
  .form-container-width {
    width: 100% !important;
    margin: 0 auto;
    padding-left: 0px;
    padding-right: 0px;
  }
  .ride-button {
    border: none;
    background: #8fe139;
    padding: 10px 10px 10px 10px;
    margin-inline-start: 2px;
    font-weight: 700;
    cursor: pointer;
  }
  .toggle-buttons {
    border: none;
    background: #b7b5b5;
    padding: 10px 10px 10px 10px;
    margin-inline-start: 2px;
    font-weight: 700;
  }
  .rating-invoice-div {
    display: block !important;
    justify-content: space-between !important;
  }
  .rating-invoice-div button {
    border: none;
    background: white;
    font-weight: 600;
    padding: 0 !important;
    margin-block: 1%;
  }
  .arrow-icon {
    color: #8fe139;
    margin-inline-start: 10px;
  }
}

@media only screen and (min-width: 929px) and (max-width: 1200px) {
  .rating-invoice-div {
    display: block !important;
    text-align: center;
  }
  .rating-invoice-div button {
    border: none;
    background: white;
    font-weight: 600;
    padding: 0 !important;
    margin-block: 1%;
  }
  .rating-div {
    justify-content: center;
  }
}
