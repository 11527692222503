.button-verify {
  text-align: center;
}
.main_section {
  width: 100%;
  background-size: 100% 100%;
  background-image: url("../../../asset/images/UserDetailBackgroung.png");
}
.sub-main-section-otp {
  width: 60%;
  margin: auto;
  background-color: white;
  padding-top: 20px;
}
.sub-main-section-otp {
  width: 60%;
  margin: auto;
  background-color: white;
  padding-top: 20px;
}
.sub_main_form_section input {
  height: 44px !important;
  -moz-box-shadow: inset 0 0 21px #00000017;
  box-shadow: inset 0 0 21px #00000017;
  border-radius: 0px;
}
.label-verify {
  border-bottom: 2px solid;
  float: left;
}

.verify_otp_heading {
  padding-top: 20px;
}

.top_section {
  margin-top: 30px;
}

.sub_main_form_section h2 {
  text-align: center;
}

.sub_main_form_section {
  width: 70%;
  margin: auto;
}
.otp_verify_button_div {
  display: flex;
  justify-content: center;
  padding: 15px 20px 30px 20px;
}

.otp_verify_button_div button {
  background: #8fe139;
  color: white;
  border: none;
  padding: 10px 50px 10px 50px;
  border-radius: 30px;
  width: 300px;
  margin-block-start: 15px;
  font-size: large;
  font-weight: 600;
}

.otp_verify_button_div button:hover {
  color: black;
  background: #8fe139;
  transition: all 0.5s ease-out 0s;
}

.otp_verify_button_div button:focus {
  outline: none !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(254, 94, 0, 0.25);
  background: #8fe139;
  transition: all 0.5s ease-out 0s;
  color: #000000;
}

.otp_verify_button_disabled {
  text-align: center;
  justify-content: center;
  padding: 15px 20px 30px 20px;
}

.otp_verify_button_disabled button {
  background: #b7b5b5 !important;
  color: white;
  border: none;
  padding: 10px 50px 10px 50px;
  border-radius: 30px;
  width: 300px;
  margin-block-start: 15px;
  font-size: large;
  font-weight: 600;
}

.otp_verify_button_disabled button:hover {
  color: black;
  background: #8fe139;
  transition: all 0.5s ease-out 0s;
}

.otp_verify_button_disabled button:focus {
  outline: none !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(254, 94, 0, 0.25);
  background: #8fe139;
  transition: all 0.5s ease-out 0s;
  color: #000000;
}

.resend_otp_button {
  cursor: pointer;
  color: #007bff;
  font-weight: bold;
  width: max-content;
  padding: 9px 0px 0px 15px;
}

.resend_buton_div {
  text-align: right;
}

.input_fitting {
  margin-top: 15px;
  width: 210%;
  display: inline-flex;
}
.otp_error_message {
  font-size: 15px;
  font-weight: 450;
  color: red;
  float: left;
  width: max-content;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .label-verify {
    border-bottom: 2px solid;
    float: left;
  }
  .resend_buton_div {
    text-align: right;
    margin-block: auto;
  }
}

@media only screen and (min-width: 480px) and (max-width: 569px) {
  .resend_buton_div {
    text-align: right;
    margin-block: auto;
  }
  .label-verify {
    border-bottom: 2px solid;
    float: left;
    display: flex;
  }
  .otp_verify_button_disabled button {
    background: #b7b5b5 !important;
    color: white;
    border: none;
    padding: 10px 50px 10px 50px;
    border-radius: 30px;
    width: -webkit-fill-available;
    margin-block-start: 0px;
    font-size: large;
    font-weight: 600;
  }
  .otp_verify_button_disabled {
    text-align: center;
    justify-content: center;
    padding: 30px 20px 30px 20px;
  }
  .main_section {
    overflow: hidden;
    width: 100%;
    float: left;
  }
  .otp_verify_button_div {
    display: flex;
    justify-content: center;
    padding: 0px 20px 30px 20px;
  }
  .input_fitting {
    margin-top: 15px;
    width: auto;
    /* display: block !important; */
  }
  .resend_buton_div {
    text-align: right;
  }
  .resend_otp_button {
    cursor: pointer;
    color: #1a73e8;
    font-weight: bold;
    width: max-content;
    padding: 5px 0px 0px 15px;
  }
}
@media only screen and (min-width: 570px) and (max-width: 767px) {
  .resend_buton_div {
    text-align: right;
    margin-block: auto;
  }
  .otp_verify_button_disabled button {
    background: #b7b5b5 !important;
    color: white;
    border: none;
    padding: 10px 50px 10px 50px;
    border-radius: 30px;
    width: -webkit-fill-available;
    margin-block-start: 0px;
    font-size: large;
    font-weight: 600;
  }
  .otp_verify_button_div button {
    background: #8fe139;
    color: white;
    border: none;
    padding: 10px 50px 10px 50px;
    border-radius: 30px;
    width: 300px;
    margin-block-start: 0px;
    font-size: large;
    font-weight: 600;
  }
  .otp_verify_button_disabled {
    text-align: center;
    margin-block: 10%;
  }
  .label-verify {
    border-bottom: 2px solid;
    float: left;
    display: flex;
  }
  .otp_verify_button_div {
    display: flex;
    justify-content: center;
    padding: 0px 20px 30px 20px;
  }
  .input_fitting {
    margin-top: 15px;
  }
  .resend_buton_div {
    text-align: right;
  }
  .resend_otp_button {
    cursor: pointer;
    color: #1a73e8;
    font-weight: bold;
    width: max-content;
    margin-block-start: 15px;
    padding: 5px 0px 0px 15px;
  }
}

@media only screen and (min-width: 240px) and (max-width: 479px) {
  .sub-main-section-otp {
    width: 90%;
    margin: auto;
    background-color: white;
    padding-top: 20px;
  }
  .otp_verify_button_disabled button {
    width: -webkit-fill-available;
  }
  .otp_verify_button_disabled {
    text-align: center;
    justify-content: center;
    padding: 0px 20px 30px 20px;
  }
  /* .form-group {
    margin-bottom: 0rem;
  } */
  .top_margin {
    margin-top: 0px;
  }
  .label-verify {
    border-bottom: 2px solid;
    float: left;
    font-size: 13px;
  }
  .main_section {
    overflow: hidden;
    width: 100%;
    float: left;
  }
  .sub_main_form_section {
    width: 95%;
    margin: auto;
  }
  .input_fitting {
    margin-top: 15px;
    width: auto;
    /* display: block; */
  }
  .resend_buton_div {
    text-align: right;
    margin-block: auto;
  }
  .resend_otp_button {
    cursor: pointer;
    color: #1a73e8;
    font-weight: bold;
    width: max-content;
    padding: 5px 0px 0px 15px;
  }
  .otp_verify_button_div {
    display: flex;
    justify-content: center;
    padding: 0px 20px 30px 20px;
  }

  .otp_verify_button_div button {
    background: #8fe139;
    color: white;
    border: none;
    padding: 10px 40px 10px 40px;
    border-radius: 30px;
    width: 300px;
    margin-block-start: 15px;
  }

  .otp_verify_button_div button:hover {
    color: black;
    background: #8fe139;
    transition: all 0.5s ease-out 0s;
  }

  .otp_verify_button_div button:focus {
    outline: none !important;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(254, 94, 0, 0.25);
    background: #8fe139;
    transition: all 0.5s ease-out 0s;
    color: #000000;
  }
}
