.div-center {
  text-align: center;
}
.save-buttons-div {
  width: 100%;
  margin: 50px 0px 50px 0px;
  text-align: center;
}
.create-invoice-div {
  text-align: center;
  margin: 30px 0px 20px 0px;
}
.create-invoice-button {
  border: none;
  background: #8fe139;
  padding: 10px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  padding: 10px 30px 10px 30px;
}
.create-invoice-div hr {
  color: #b7b5b5;
  border-top: 2px solid;
  margin: 30px 0px 30px 0px;
}
.tax-profile-div button:hover {
  color: white;
}
.create-invoice-div button:hover {
  color: white;
}
.save-button {
  border: none;
  background: #8fe139;
  margin-inline-start: 2px;
  font-weight: 700;
  cursor: pointer;
  padding: 10px 30px 10px 30px;
}
.edit-delete-div {
  display: flex;
  justify-content: space-between;
}
.edit-delete-div label {
  margin: 9px 0px 0px 0px;
  font-weight: 500;
  font-size: large;
}
.edit-delete-button button {
  border: none;
  background: #b7b5b5;
  padding: 10px 30px 10px 30px;
  font-weight: 700;
  margin-inline-start: 40px;
}
.edit-delete-button button:hover {
  background: #8fe139;
  color: white;
}
.delete-button:hover {
  background: red !important;
  color: white !important;
}
.cancel-button {
  border: none;
  background: #b7b5b5;
  padding: 10px 30px 10px 30px;
  margin-inline-start: 2px;
  font-weight: 700;
  margin-inline-start: 50px;
}
.inputs-labels-row-section {
  width: 50%;
  margin-top: 20px;
  text-align: left;
}
.left {
  margin-left: 21px;
}
.d_flex {
  display: flex !important;
  width: 80%;
  margin: 0 auto;
}
.tax-profile-div input {
  height: 44px !important;
  -moz-box-shadow: inset 0 0 21px #00000017;
  box-shadow: inset 0 0 21px #00000017;
  border-radius: 0px;
}
.tax-profile-div select {
  height: 44px !important;
  -moz-box-shadow: inset 0 0 21px #00000017;
  box-shadow: inset 0 0 21px #00000017;
  border-radius: 0px;
}
.input-div {
  width: 80%;
  text-align: left;
  margin-top: 20px;
  display: inline-grid;
  padding: 0px 20px 0px 20px;
}
.label {
  font-size: 16px;
  text-align: left;
  font-weight: 500;
  color: black;
  margin-top: 2px;
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .input-div {
    width: 100%;
  }
  .save-buttons-div {
    width: 100%;
    margin: 50px 0px 50px 0px;
    text-align: center;
    padding-block-end: 10%;
  }
  .d_flex {
    display: flex !important;
    width: 100%;
    margin: 0 auto;
  }
  .create-invoice-div {
    text-align: center;
    margin: 30px 20px 20px 20px;
    padding-block-end: 10%;
  }
  .edit-delete-div {
    display: block;
    justify-content: center;
  }
  .edit-delete-button {
    margin-block-start: 5%;
    justify-content: space-evenly;
    display: flex;
  }
}
@media only screen and (min-width: 240px) and (max-width: 479px) {
  .create-invoice-div {
    text-align: center;
    margin: 30px 20px 20px 20px;
    padding-block-end: 10%;
  }
  .cancel-button {
    padding: 10px 20px 10px 20px;
    font-size: 13px;
    margin-inline-start: 10px;
  }
  .save-button {
    padding: 10px 20px 10px 20px;
    font-size: 13px;
  }
  .save-buttons-div {
    width: 100%;
    margin: 50px 0px 50px 0px;
    text-align: center;
    padding-block-end: 10%;
  }
  .edit-delete-div {
    display: block;
    justify-content: center;
    margin-block-start: -7%;
  }
  .tax-button {
    border: none;
    background: #8fe139;
    padding: 10px 10px 10px 10px;
    margin-inline-start: 2px;
    font-weight: 700;
    cursor: pointer;
  }
  .edit-delete-button button {
    border: none;
    background: #b7b5b5;
    padding: 10px 20px 10px 20px;
    font-weight: 700;
    font-size: 13px;
    margin-inline-start: 10px;
  }
  .input-div {
    width: 100%;
    margin: 0 auto;
    margin-block-start: 15px;
  }
  .label {
    font-size: 12px;
    text-align: left;
    font-weight: 500;
    color: black;
    margin-top: 2px;
  }
  .d_flex {
    display: block !important;
    width: 100%;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1168px) {
  .input-div {
    width: 100%;
  }
  .d_flex {
    display: flex !important;
    width: 100%;
    margin: 0 auto;
  }
  .create-invoice-div {
    text-align: center;
    margin: 30px 20px 20px 20px;
    padding-block-end: 10%;
  }
  .edit-delete-div {
    display: block;
    justify-content: center;
  }
  .edit-delete-button {
    margin-block-start: 5%;
    justify-content: space-evenly;
    display: flex;
  }
}
