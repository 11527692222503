.Header_Section {
  width: 100%;
  background-color: #181d33;
  display: grid;
}
.logo-ride-partner-div img {
  width: 130px;
}
.support-email-a {
  color: white;
}
.links-div-button:hover {
  color: black !important;
  background: #8fe139;
  transition: all 0.5s ease-out 0s;
}
.links-div button:hover {
  color: black !important;
  cursor: pointer;
  transition: all 0.5s ease-out 0s;
}
.links-div-button:hover {
  color: black !important;
}
.search-box {
  border: none;
  padding: 6px 20px 7px 20px;
  border-radius: 30px;
  outline: none;
  padding-inline-end: 40px;
  position: relative;
  margin-top: 3%;
}
.search-icon {
  color: #8fe139;
  font-size: 20px;
  position: absolute;
  margin: 3.6% 0% 0% -2.5%;
  cursor: pointer;
}
.download-app-button {
  background: #8fe139;
  color: #fff !important;
  border: none;
  border-radius: 30px;
  padding: 6px 20px 7px 20px;
  margin-right: 3%;
  margin-top: 3%;
}
.express_logo_Header {
  padding: 20px 20px 20px 20px;
  object-fit: contain;
}
.logo-ride-partner-div {
  text-align: center;
  margin: 20px 0px 0px 0px;
}
.links-div {
  float: right;
  margin: 0px 0px 30px 0px;
  text-align: center;
  width: -webkit-fill-available;
}
.links-div a {
  margin-right: 3%;
  font-size: 15px;
  text-decoration: none;
  color: white;
  display: inline-block;
}
.links-div a:hover {
  text-decoration: none;
  color: #8fe139;
}
.links-div-button {
  background: #8fe139;
  color: white;
  border: none;
  border-radius: 30px;
  padding: 6px 20px 7px 20px;
  transition: all 0.5s ease-out 0s;
}
.links-div-button:focus {
  outline: none !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(175, 173, 172, 0.25);
  background: #8fe139;
  transition: all 0.5s ease-out 0s;
  color: #000000;
}
@media only screen and (min-width: 240px) and (max-width: 527px) {
  .search-icon {
    color: #8fe139;
    font-size: 20px;
    position: absolute;
    margin: 5% 0% 0% -9%;
    cursor: pointer;
  }
}
@media only screen and (min-width: 527px) and (max-width: 727px) {
  .search-icon {
    color: #8fe139;
    font-size: 20px;
    position: absolute;
    margin: 4.5% 0% 0% -5%;
    cursor: pointer;
  }
}
@media only screen and (min-width: 727px) and (max-width: 927px) {
  .search-icon {
    color: #8fe139;
    font-size: 20px;
    position: absolute;
    margin: 4% 0% 0% -4%;
    cursor: pointer;
  }
}
@media only screen and (min-width: 927px) and (max-width: 1227px) {
  .search-icon {
    color: #8fe139;
    font-size: 20px;
    position: absolute;
    margin: 3.8% 0% 0% -3%;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1149px) {
  .Header_Section {
    width: 100%;
    background-color: #181d33;
    display: flex;
    justify-content: space-between;
  }

  .express_logo_Header {
    padding: 20px;
    object-fit: contain;
  }
  .logo-ride-partner-div {
    float: left;
  }
  .links-div {
    float: right;
    align-self: flex-end;
  }
  .links-div a {
    margin-right: 45px;
    font-size: 20px;
    text-decoration: none;
    color: white;
  }
  .links-div a:hover {
    text-decoration: none;
    color: #8fe139;
  }
  .links-div-button {
    background: #8fe139;
    color: white;
    border: none;
    border-radius: 30px;
    padding: 6px 20px 7px 20px;
    transition: all 0.5s ease-out 0s;
    margin-right: 15px !important;
  }
  .links-div-button:hover {
    color: black !important;
    background: #8fe139;
    transition: all 0.5s ease-out 0s;
  }
  .links-div-button:focus {
    outline: none !important;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(175, 173, 172, 0.25);
    background: #8fe139;
    transition: all 0.5s ease-out 0s;
    color: #000000;
  }
}

@media only screen and (min-width: 861px) and (max-width: 1158px) {
  .Header_Section {
    width: 100%;
    background-color: #181d33;
    display: grid;
    justify-content: space-between;
  }

  .express_logo_Header {
    padding: 20px;
    object-fit: contain;
  }
  .logo-ride-partner-div {
    float: left;
  }
  .logo-ride-partner-div img {
    height: 109px;
  }
  .links-div {
    float: right;
    align-self: flex-end;
  }
  .links-div img {
    width: 170px;
  }
  .links-div a {
    margin-right: 30px;
    font-size: 14px;
    text-decoration: none;
    color: white;
  }
  .links-div a:hover {
    text-decoration: none;
    color: #8fe139;
  }
  .links-div-button {
    background: #8fe139;
    color: white;
    border: none;
    border-radius: 30px;
    padding: 6px 20px 7px 20px;
    transition: all 0.5s ease-out 0s;
    margin-right: 15px !important;
  }
  .links-div-button:hover {
    color: black !important;
    background: #8fe139;
    transition: all 0.5s ease-out 0s;
  }
  .links-div-button:focus {
    outline: none !important;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(175, 173, 172, 0.25);
    background: #8fe139;
    transition: all 0.5s ease-out 0s;
    color: #000000;
  }
}

@media only screen and (min-width: 527px) and (max-width: 860px) {
  .Header_Section {
    width: 100%;
    background-color: #181d33;
    display: grid;
    text-align: center;
  }
  .express_logo_Header {
    width: 161px;
  }
  .logo-ride-partner-div {
    width: 100%;
  }
  .logo-ride-partner-div img {
    width: 150px;
  }
  .links-div {
    width: 100%;
  }
  .links-div a {
    margin-right: 30px;
    font-size: 13px;
    text-decoration: none;
    color: white;
  }
  .links-div a:hover {
    text-decoration: none;
    color: #8fe139;
  }
  .links-div-button {
    background: #8fe139;
    color: white;
    border: none;
    border-radius: 30px;
    padding: 6px 20px 7px 20px;
    transition: all 0.5s ease-out 0s;
    margin-right: 15px !important;
  }
  .links-div-button:hover {
    color: black !important;
    background: #8fe139;
    transition: all 0.5s ease-out 0s;
  }
  .links-div-button:focus {
    outline: none !important;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(175, 173, 172, 0.25);
    background: #8fe139;
    transition: all 0.5s ease-out 0s;
    color: #000000;
  }
}

@media only screen and (min-width: 240px) and (max-width: 526px) {
  .Header_Section {
    width: 100%;
    background-color: #181d33;
    display: grid;
    text-align: center;
  }
  .edit-delete-button {
    margin-block-start: 10%;
    justify-content: center;
    display: flex;
  }
  .Header_Section img {
    width: 100px;
  }
  .express_logo_Header {
    width: 125px !important;
    padding: 20px 0px 20px 20px;
  }
  .logo-ride-partner-div {
    width: 100%;
  }
  .logo-ride-partner-div img {
    width: 100px;
  }
  .links-div {
    width: 100%;
  }
  .links-div a {
    margin-right: 30px;
    font-size: 13px;
    text-decoration: none;
    color: white;
    display: inline-block;
    margin-top: 4%;
  }
  .download-app-button {
    background: #8fe139;
    color: #fff !important;
    border: none;
    font-size: 13px;
    border-radius: 30px;
    padding: 6px 20px 7px 20px;
    margin-right: 3%;
    margin-top: 5%;
  }
  .links-div a:hover {
    text-decoration: none;
    color: #8fe139;
  }
  .links-div-button {
    background: #8fe139;
    color: white;
    border: none;
    border-radius: 30px;
    padding: 6px 20px 7px 20px;
    transition: all 0.5s ease-out 0s;
    margin-right: 15px !important;
  }
  .links-div-button:hover {
    color: black !important;
    background: #8fe139;
    transition: all 0.5s ease-out 0s;
  }
  .links-div-button:focus {
    outline: none !important;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(175, 173, 172, 0.25);
    background: #8fe139;
    transition: all 0.5s ease-out 0s;
    color: #000000;
  }
}
